.location-list {
  padding: 20px;
}

.content-wrapper {
  margin-bottom: 20px;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
}

.location-box {
  display: block;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-decoration: none;
}

.location-image-wrapper {
  position: relative;
  padding-top: 75%; /* 4:3 aspect ratio */
  overflow: hidden;
  background-color: #fff; /* Add white background color */
}

.location-name {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Change text color to black */
  text-align: center;
  background-color: #fff; /* Add white background color */
}

@media screen and (max-width: 768px) {
  .location-list {
    padding: 0;
  }
}