.category-list {
  padding: 20px;
}

.categories-section {
  margin: 40px 0;
}

.category-card {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-decoration: none;
  background-color: #fefae0;
}

.category-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
}