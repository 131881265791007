.footer {
  background-color: #fefae0;
  padding: 40px 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left,
.footer-center,
.footer-right {
  flex: 1;
  padding: 0 20px;
}

.logo-container {
  width: 100%; /* Make the container take up the full width */
  height: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  width: 80x; /* Adjust the width to your desired size */
  height: auto;
  max-width: 80%; /* Ensure the logo doesn't exceed the container width */
}

.contact-info,
.social-media-links {
  margin-top: 20px;
}

.footer-center h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-center ul,
.footer-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-center li,
.footer-right li {
  margin-bottom: 8px;
}

.footer-right a {
  color: #000000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-right a:hover {
  color: #555555;
}

.footer-right .ant-btn-link {
  padding: 0;
  color: #000000;
}

.footer-right .ant-btn-link:hover {
  color: #555555;
}