.home {
  font-family: 'Arial', sans-serif;
}

/* Hero Section */
.hero-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/backround.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: 1;
}
.hero-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  max-width: 1200px;
  width: 100%;
  text-align: center;
  color: #000000;
}

.contact-form-card {
  background-color: #dda15e;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin-bottom: 24px;
}

.contact-form-card h2 {
  font-size: 34px;
  margin-bottom: 16px;
  color: #000000;
}

.hero-text {
  text-align: center;
  color: #000000;
}
.hero-text h1{
  font-size: 66;
}

.main-text {
  font-size: 88px;
  font-weight: bold;
  color: #000000;
  padding: 30px 20px;
  display: inline-block;
  margin-bottom: 20px;
  text-align: left;
  
}


.sub-text {
  font-size: 38px;
  color: #000000;
  margin-bottom: 40px;
  text-align: left;

}

.social-proof {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.satisfied-customers {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.satisfied-customers img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.average-rating {
  display: flex;
  align-items: center;
}

.star-icon {
  color: #ffcc00;
  margin-right: 5px;
}

/* Content Wrapper */
.content-wrapper {
  padding: 40px;
  margin: 0 auto;
  max-width: 1200px;
}

/* Client Reviews Section */
.review-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 24px;
}

.review-item {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.review-photo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.review-info h4 {
  margin: 0;
}

.review-rating {
  color: #ffcc00;
  margin-top: 5px;
}

.review-text {
  margin: 0;
}


/* Newest Maternity Photographers Section */
.business-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 24px;
}

.business-image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.business-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.business-details {
  padding: 16px;
  text-align: center;
}

.business-name {
  margin-bottom: 16px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.feature-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 16px;
}

.feature-box {
  background-color: #f5f5f5;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 4px;
}

.quick-quote-button {
  display: block;
  margin: 0 auto;
  background-color: #bc6c25;
  border-color: #283618;
  color:#f0f0f0;
}

.view-more-button {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.view-more-button button {
  background-color: #283618;
  border-color: #283618;
}


/* Featured Packages Section */
.package-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.package-card .ant-card-meta-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.package-card .ant-card-meta-description {
  margin-bottom: 20px;
}

.package-price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* FAQ Section */
.faq-section {
  text-align: center;
}

.faq-item {
  margin-bottom: 24px;
}

.faq-item .ant-typography {
  text-align: left;
}

/* Customer Logos Section */
.customer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
}

.customer-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Newsletter Section */
.newsletter-section {
  text-align: center;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Mobile Responsiveness */
@media screen and (min-width: 768px) {
  .hero-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .contact-form-card {
    margin-right: 40px;
    margin-bottom: 0;
  }

  .hero-text {
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .hero-section {
    height: auto;
  }

  .hero-content {
    padding: 40px;
  }

  .main-text {
    font-size: 32px;
  }

  .sub-text {
    font-size: 18px;
  }

  .satisfied-customers img {
    width: 20px;
    height: 20px;
  }

  .content-wrapper {
    padding: 20px;
  }

  .review-item {
    margin-bottom: 16px;
  }

  .business-card {
    margin-bottom: 16px;
  }

  .business-image {
    height: 150px;
  }

  .button-group {
    justify-content: center;
  }
}
.custom-button {
  background-color: #bc6c25;
  border-color: #bc6c25;
  color: #ffffff;
}

.custom-button:hover,
.custom-button:focus {
  background-color: #a05b20;
  border-color: #a05b20;
  color: #ffffff;
}