@import url('https://fonts.googleapis.com/css2?family=Brockmann&display=swap');

.business-list {
  padding: 2rem 0;
  font-family: 'Brockmann', sans-serif;
}

.title-section {
  text-align: center;
  margin-bottom: 2rem;
}

.title-section h1 {
  font-size: 7.5rem;
  margin-bottom: 1rem;
}

.title-section p {
  font-size: 2.2rem;
  color: #666;
}

.review-info {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.review-info > div {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.review-info > div:last-child {
  margin-right: 0;
}

.review-info svg {
  margin-right: 0.5rem;
  color: #bc6c25;
}

.business-card {
  display: flex;
  margin-bottom: 2rem;
  background-color: #fefae0;
  border-radius: 8px;
}

.business-card img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.business-card .MuiCardContent-root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.business-card h5 {
  margin-bottom: 1rem;
}

.business-card .MuiBox-root {
  margin-bottom: 0.5rem;
}

.business-card .MuiBox-root svg {
  margin-right: 0.5rem;
  color: #bc6c25;
}

.feature-box {
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.trust-score-box {
  background-color: #2196f3;
  color: #fff;
  padding: 1rem;
  border-radius: 4px;
  margin-top: auto;
}

.contact-form-section {
  text-align: center;
  margin-top: 2rem;
}

.contact-form-section h5 {
  margin-bottom: 1rem;
}

.contact-form-section p {
  color: #666;
  margin-bottom: 1rem;
}

.contact-form-section .MuiButton-root {
  margin-top: 1rem;
}

.cost-calculator {
  margin-top: 2rem;
}

.cost-calculator .MuiCard-root {
  padding: 1rem;
}

.cost-calculator .MuiGrid-item {
  display: flex;
  align-items: center;
}

.cost-calculator .MuiTextField-root {
  margin-right: 1rem;
}

.cost-calculator .MuiButton-root {
  margin-left: auto;
}

.checklist-section {
  margin-top: 2rem;
}

.checklist-section .MuiCard-root {
  height: 100%;
}

.checklist-section .MuiTypography-h6 {
  margin-bottom: 0.5rem;
}

.checklist-section .MuiTypography-body2 {
  color: #666;
}
.business-list {
  padding: 2rem 0;
}

.title-section {
  text-align: center;
  margin-bottom: 2rem;
}

.title-section h1 {
  font-size: 4.5rem;
  margin-bottom: 1rem;
}

.title-section p {
  font-size: 1.2rem;
  color: #666;
}

.review-info {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.review-info > div {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.review-info > div:last-child {
  margin-right: 0;
}

.review-info svg {
  margin-right: 0.5rem;
}

.business-card {
  display: flex;
  margin-bottom: 2rem;
  background-color: fefae0;
}

.business-card img {
  width: 100%;
  object-fit: cover;
  border-radius: 4px 0 0 4px;
}

.business-card .MuiCardContent-root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.business-card h5 {
  margin-bottom: 1rem;
}

.business-card .MuiBox-root {
  margin-bottom: 0.5rem;
}

.business-card .MuiBox-root svg {
  margin-right: 0.5rem;
}

.feature-box {
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.trust-score-box {
  background-color: #2196f3;
  color: #fff;
  padding: 1rem;
  border-radius: 4px;
  margin-top: auto;
}

.contact-form-section {
  text-align: center;
  margin-top: 2rem;
}

.contact-form-section h5 {
  margin-bottom: 1rem;
}

.contact-form-section p {
  color: #666;
  margin-bottom: 1rem;
}

.contact-form-section .MuiButton-root {
  margin-top: 1rem;
}

.cost-calculator {
  margin-top: 2rem;
}

.cost-calculator .MuiCard-root {
  padding: 1rem;
}

.cost-calculator .MuiGrid-item {
  display: flex;
  align-items: center;
}

.cost-calculator .MuiTextField-root {
  margin-right: 1rem;
}

.cost-calculator .MuiButton-root {
  margin-left: auto;
}

.checklist-section {
  margin-top: 2rem;
}

.checklist-section .MuiCard-root {
  height: 100%;
}

.checklist-section .MuiTypography-h6 {
  margin-bottom: 0.5rem;
}

.checklist-section .MuiTypography-body2 {
  color: #666;
}